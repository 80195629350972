@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900');

:root {
    --blue: #007AFF;
    --yellow: #FFCC00;
    --orange: #F26628;
    --pink: #FF2D55;
    --indigo: #5856D6;
    --aqua: #10AEBC;

    --background-grey: #F2F2F7;
    --text-grey: #7A7B7C;
    --button-grey: #E3E3ED;
    --spring: cubic-bezier(.91,.09,.13,.82);
    --spring2: cubic-bezier(.65,.02,.0,.98);
    --spring3: cubic-bezier(.65,.02,.24,.89);
}

::selection {
    color: white;
    background: black;
}

h1, h2, h3, h4, h5, h6, p, a, li {
    font-family: "Inter", sans-serif;
}

* {
    padding: 0;
    margin: 0;
    border: 0 none;
    outline: 0;
    text-decoration: none;
    list-style: none;
    appearance: none;
}

body {
    background-color: var(--background-grey);
}
