div.bodyText {
    margin-top: 100px;
    padding-left: 150px;
}

.phrase {
    width: 350px;
    opacity: 0;
}

.subtitle {
    width: 870px;
    opacity: 0;
}

.textContainer {
    margin-top: 120px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}