
.Page input {
    background-color: var(--background-grey);
    padding-bottom: 7px;
}

.Button input {
    background-color: var(--button-grey);
    display: flex;
    padding: 18px 55px;
    justify-content: center;
    align-items: center;
    border-radius: 104px;
    transition: background 250ms ease-in-out;
}

.Page hr:not(#highlight) {
    border-top: 1px solid black;
    width: 0;
    transition: width 250ms ease-in-out;
}

.Page input:not(#highlight):hover + hr {
    width: 100%;
    transition: width 250ms ease-in-out;
}

.Page input:hover {
    cursor: pointer;
}

.Page input#highlight + hr {
    width: 100%;
    transition: width 250ms ease-in-out;
}

.Button input:hover {
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background 250ms ease-in-out;
}