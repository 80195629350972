
.card {
    width: 390px;
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.card .image {
    height: 325px;
    width: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.card .image .rectangle {
    height: 325px;
    width: 390px;
    background-color: white;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: start;
    transition: all 300ms var(--spring2);
}

.card .image .rectangle:hover {
    height: 310px;
    width: 375px;
    cursor: pointer;
    transition: all 300ms var(--spring2);
}

.card .image .rectangle .imageContainer {
    top: -105px;                
    position: relative;
    display: flex;
    justify-content: center;
}

.card .image .rectangle .imageContainer img {
    top: 25px;
    width: 118.5px;               
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.card .image .rectangle .imageContainer h1 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: -2.55px;
    text-align: center;
    color: black;              
}

.card .textCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
}