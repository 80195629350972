
.gallery {
    margin-top: 270px;
    padding-inline: 150px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 120px;
}

.gallery .row {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    gap: 100px;
}

.gallery h3 {
    text-align: right;
    width: 870px;
}

.gallery .containerTitle {
    display: flex;
    justify-content: end;
}
