
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 70px;
    position: relative;
    background-color: var(--background-grey);
    height: 7px;
}

.centerHeader {
    display: flex;
    justify-content: center;
    gap: 25px;
    position: absolute;
    left: calc(50% - 67.25px);
}