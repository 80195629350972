
div.bodyHero {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.hero {
    top: 250px;
    height: 1000px;
}

.hero {
    display: inline-block;
    text-align: center;
    position: relative;
}

.hero h1 {
    color: var(--blue);
    animation: text-appear 1s var(--spring2);
}

.hero img {
    width: 648px;
    position: absolute;
    top: 138px;
    left: 50%;
    transform: translateX(-50%);
    animation: img-appear 1s var(--spring2);
}

@keyframes img-appear {
    0% {
        top: 400px;
        width: 500px;
    }
    100% {
        top: 138px;
        width: 648px;
    }
}

@keyframes text-appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}