
.selectedProject {
    margin-top: 270px;
    padding-inline: 150px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.frameRectangle {
    width: inherit;
    height: 871px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.rectangleBig img {
    width: 1200px;
    height: 670.43px;
    transition: all 300ms var(--spring2);
}

.rectangleBig {
    display: flex;
    align-content: center;
    align-items: center;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: white;
    border-radius: 40px;
    cursor: pointer;
    transition: padding 300ms var(--spring2);
}

.rectangleBig:hover {
    padding-top: 80px;
    padding-bottom: 80px;
    transition: padding 300ms var(--spring2);
}

.rectangleBig:hover img {
    transform: scale(1.02);
    transition: all 300ms var(--spring2);
}

.row1 {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.row2 {
    display: flex;
    justify-content: start;
    align-content: center;
    gap: 400px;
}

.row2 p {
    color: black;
}

.discoverRow {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 7px;
}