
/* Bigtitle */
h1 {
    font-size: 270px;
    font-weight: 600;
    line-height: 96.023%;
    letter-spacing: -15px;
}

/* Title */
h2 {
    font-size: 100px;
    font-weight: 600;
    letter-spacing: -4.5px;
}

/* Subtitle */
h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 118.5%;
    letter-spacing: -1.17px;
}

/* Headline */
h4, p, input {
    font-size: 17px;
    font-weight: 500;
    line-height: 149%;
    letter-spacing: -0.35px;
}

/* Body */
p {
    color: #7A7B7C;
    font-weight: 400;
}